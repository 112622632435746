/**
 * Suggestions
 */
require('./elements/suggestions');

/**
 * /application/new - adding new work/prop
 */
require('./elements/application-page');

/**
 * DatePicker
 */
require('./elements/datepicker');

/**
 * CSS
 */
//require('../css/app.css');
/**
 * SASS
 */
require('../scss/app.scss');

/**
 * Vue
 */
//require('../vue/app');