require('suggestions-jquery');

$(document).ready(function () {
	$('.suggester-name').suggestions({
		serviceUrl: "/suggester",
		type: "NAME"
	});

	$('.suggester-email').suggestions({
		serviceUrl: "/suggester",
		type: "email"
	});

	$('.suggester-country').suggestions({
		serviceUrl: "/suggester",
		type: "country"
	});

	$('.suggester-address').suggestions({
		serviceUrl: "/suggester",
		type: "address"
	});
});