/** Forms Validation */
(function() {
	'use strict';
	window.addEventListener('load', function() {
		// Fetch all the forms we want to apply custom Bootstrap validation styles to
		let forms = document.getElementsByClassName('needs-validation');
		// Loop over them and prevent submission
		let validation = Array.prototype.filter.call(forms, function(form) {
			form.addEventListener('submit', function(event) {
				if (form.checkValidity() === false) {
					event.preventDefault();
					event.stopPropagation();
				}
				form.classList.add('was-validated');
			}, false);
		});
	}, false);
})();

$(document).ready(function () {
	/** Float labels SelectBoxes&Inputs */
	$(document).on('click', 'select', function () {
		this.setAttribute('data-value', this.value)
	});

	function initCustomLabels() {
		$('select').attr('data-value', '').click();
		$('input').attr('placeholder', ' ');
	}
	/** Float labels SelectBoxes&Inputs end */

	$(document).on('click', '.add-entity', function () {
		const $this = $(this);
		let prototype = $this.data('prototype');
		if (prototype) {
			const cnt = $('.'+$(prototype).first().attr('class')).length;
			$this.before(prototype.replace(/__name__/g, cnt));
			initCustomLabels();
		}
	});

	initCustomLabels();

	/** Init Employment form */
	if($('.worksPrototype').length === 0) {
		$('.add-entity.employment').click();
	}
});